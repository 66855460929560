import PropTypes from 'prop-types';
import Link from '@/components/Link';
import NewBadge from './NewBadge';

const defaultProps = {
  isNew: false,
};

const propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
};

export default function MenuItem({ url, title, isNew }) {
  return (
    <Link
      className="-mx-2 my-px inline-flex items-center py-1 px-2 font-medium leading-normal text-gray-900 transition duration-300 ease-in-out hover:text-gray-600 lg:text-sm"
      href={url}
    >
      <span className="block flex-1">{title}</span>
      {isNew && <NewBadge />}
    </Link>
  );
}

MenuItem.defaultProps = defaultProps;
MenuItem.propTypes = propTypes;
