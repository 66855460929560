import FooterMenuCompany from './FooterMenuCompany';
import FooterMenuTrainings from './FooterMenuTrainings';
import FooterMenuResources from './FooterMenuResources';
import FooterMenuCommunity from './FooterMenuCommunity';

export default function FooterMenu() {
  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-12">
      <div className="grid grid-cols-1 gap-6 sm:col-span-6 md:grid-cols-2">
        <FooterMenuCompany />
        <FooterMenuTrainings />
      </div>
      <div className="grid grid-cols-1 gap-6 sm:col-span-6 md:grid-cols-2">
        <FooterMenuResources />
        <FooterMenuCommunity />
      </div>
    </div>
  );
}
