import Container from '@/components/Container';
import About from '@/view/About';
import Social from '@/view/Social';
import SecurePayments from '@/view/SecurePayments';
import Copyright from '@/view/Copyright';
import FooterMenu from './FooterMenu';

export default function Footer() {
  return (
    <footer
      className="relative bg-white text-gray-800 lg:pt-10"
      role="contentinfo"
    >
      <div className="relative z-10 flex w-full flex-col">
        <Container>
          <div className="grid grid-cols-1 space-y-10 py-10 md:grid-cols-12 md:space-y-0 lg:grid-cols-12 lg:space-y-0 lg:space-x-10 xl:pb-16">
            <div className="col-span-1 space-y-6 md:col-span-12 lg:col-span-3">
              <About />
              <Social />
            </div>
            <div className="col-span-1 md:col-span-12 lg:col-span-9">
              <FooterMenu />
            </div>
          </div>
          <div className="border-t border-gray-200">
            <div className="flex flex-col space-y-4 py-6 text-gray-600 md:flex-row md:items-center md:space-x-6 md:space-y-0 lg:space-x-0 lg:py-10">
              <div className="flex items-center md:flex-1 lg:space-x-4">
                <Copyright />
              </div>
              <SecurePayments />
            </div>
          </div>
        </Container>
      </div>
      <div className="pointer-events-none absolute inset-0 z-0 opacity-50 dark:bg-black" />
    </footer>
  );
}
