import PropTypes from 'prop-types';

const defaultProps = {};

const propTypes = {
  children: PropTypes.node.isRequired,
};

export default function Picture({ children }) {
  return <picture>{children}</picture>;
}

Picture.defaultProps = defaultProps;
Picture.propTypes = propTypes;
