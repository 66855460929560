import SocialLink from '@/view/Social/SocialLink';

export default function Facebook() {
  return (
    <SocialLink title="Facebook" href="https://www.facebook.com/AIBE.Scrum">
      <svg
        className="h-auto max-w-full fill-current"
        role="img"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Facebook</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM26.5016 25.0542V38.1115H21.0991V25.0547H18.4V20.5551H21.0991V17.8536C21.0991 14.1828 22.6231 12 26.9532 12H30.5581V16.5001H28.3048C26.6192 16.5001 26.5077 17.1289 26.5077 18.3025L26.5016 20.5546H30.5836L30.1059 25.0542H26.5016Z"
          fill="current"
        />
      </svg>
    </SocialLink>
  );
}
