import PropTypes from 'prop-types';

const defaultProps = {
  className: '',
  children: null,
};

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default function Paragraph({ className, children, ...otherProps }) {
  return (
    <p className={className} {...otherProps}>
      {children}
    </p>
  );
}

Paragraph.defaultProps = defaultProps;
Paragraph.propTypes = propTypes;
