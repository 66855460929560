import LinkedIn from './LinkedIn';
import Facebook from './Facebook';
import Instagram from './Instagram';
import YouTube from './YouTube';

export default function Social() {
  return (
    <div className="flex items-center space-x-3">
      <LinkedIn />
      <Facebook />
      <Instagram />
      <YouTube />
    </div>
  );
}
