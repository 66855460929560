/* eslint-disable @next/next/no-img-element */
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const defaultProps = {
  fluid: true,
  title: null,
  className: null,
};

const propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  fluid: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
};

function Img({ src, alt, fluid, title, className, ...rest }) {
  return (
    <img
      src={src}
      alt={alt}
      title={title}
      className={clsx(fluid && 'h-auto max-w-full', className)}
      loading="lazy"
      {...rest}
    />
  );
}

Img.defaultProps = defaultProps;
Img.propTypes = propTypes;

const ImgForwardRef = forwardRef((props, ref) => <Img ref={ref} {...props} />);

ImgForwardRef.displayName = 'ImgForwardRef';

export default ImgForwardRef;
