import Source from './Source';

export default function renderSources(sources) {
  const keys = Object.keys(sources);

  if (keys === null || keys.length === 0) {
    return null;
  }

  return keys.map((type, index) => {
    const src = sources[type];

    let srcSet = src;
    let sizes = null;

    if (Array.isArray(src) === true) {
      srcSet = src[0];
      sizes = src[1];
    }

    return renderSource(index, type, srcSet, sizes);
  });
}

function renderSource(index, type, srcSet, sizes = null) {
  return (
    <Source key={`source-${index}`} type={type} srcSet={srcSet} sizes={sizes} />
  );
}
