import Link from '@/components/Link';
import Image from '@/components/Image';

export default function PayPal() {
  return (
    <div className="w-20">
      <Link
        title="Pagos Seguros en Línea con PayPal"
        href="https://www.paypal.com/co/webapps/mpp/paypal-safety-and-security"
      >
        <Image
          src="payments/paypal.svg"
          className="h-auto max-w-full"
          alt="PayPal"
          width={124}
          height={33}
        />
      </Link>
    </div>
  );
}
