import Brand from '@/view/Brand';
import Image from '@/components/Image';

export default function About() {
  return (
    <div className="flex flex-row items-center space-x-4 lg:flex-col lg:items-start lg:space-x-0 lg:space-y-4 lg:pr-10">
      <div>
        <Brand hideName />
      </div>
      <h4 className="flex-1 text-base font-medium leading-5 tracking-tight md:text-lg md:leading-relaxed lg:flex-auto">
        Somos la certificadora preferida por hispanohablantes
      </h4>
      <div className="inline-flex font-medium leading-5 text-gray-500 lg:text-sm lg:font-normal">
        Insignias vía&nbsp;&nbsp;
        <a
          title="Asociación Iberoamericana de Scrum AIBES en Credly"
          href="https://www.credly.com/organizations/asociacion-iberoamericana-de-scrum"
        >
          <Image alt="Credly" src="credly.webp" width={50} />
        </a>
      </div>
    </div>
  );
}
