import Link from '@/components/Link';
import Image from '@/components/Image';

export default function Paymentez() {
  return (
    <div className="w-28">
      <Link
        title="Pagos Seguros en Línea con Paymentez"
        href="https://seal.controlcase.com/index.php?page=showCert&cId=2595630504"
      >
        <Image
          src="payments/paymentez.png"
          className="h-auto max-w-full"
          alt="Paymentez"
          width={800}
          height={144}
        />
      </Link>
    </div>
  );
}
