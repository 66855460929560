import Text from '@/components/Text';
import PayPal from './PayPal';
import Paymentez from './Paymentez';

export default function SecurePayments() {
  return (
    <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-y-0">
      <Text className="mr-3 whitespace-nowrap" small medium muted>
        Pagos seguros con
      </Text>
      <div className="flex items-center space-x-3">
        <Paymentez />
        <PayPal />
      </div>
    </div>
  );
}
