import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Picture from './Picture';
import Img from './Img';
import isRemote from './isRemote';
import localImageSource from './localImageSource';
import renderSources from './renderSources';

const defaultProps = {
  fluid: false,
  sources: null,
  srcSet: null,
  sizes: null,
  className: '',
};

const propTypes = {
  src: PropTypes.string.isRequired,
  sources: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  fluid: PropTypes.bool,
  className: PropTypes.string,
};

function Image({ src, sources, ...rest }) {
  const hasSources = sources && sources.length !== 0;

  return (
    <Picture>
      {hasSources && renderSources(sources)}
      <Img src={isRemote(src) ? src : localImageSource(src)} {...rest} />
    </Picture>
  );
}

Image.defaultProps = defaultProps;
Image.propTypes = propTypes;

const ImageForwardRef = forwardRef(({ alt, ...props }, ref) => (
  <Image ref={ref} alt={alt} {...props} />
));

ImageForwardRef.displayName = 'ImageForwardRef';

export default ImageForwardRef;
