import PropTypes from 'prop-types';

const defaultProps = {
  sizes: null,
  srcset: null,
  media: 'all',
};

const propTypes = {
  sizes: PropTypes.string,
  srcset: PropTypes.string,
  type: PropTypes.string.isRequired,
  media: PropTypes.oneOfType([
    PropTypes.oneOf(['all', 'print', 'screen', 'speech']),
    PropTypes.string,
  ]),
};

export default function Source({ sizes, srcset, type, media }) {
  return <source sizes={sizes} srcSet={srcset} type={type} media={media} />;
}

Source.defaultProps = defaultProps;
Source.propTypes = propTypes;
