import PropTypes from 'prop-types';
import clsx from 'clsx';
import Paragraph from '@/components/Paragraph';

const defaultProps = {
  muted: false,
  xsmall: false,
  small: false,
  large: false,
  medium: false,
  semibold: false,
  className: '',
  children: '',
};

const propTypes = {
  muted: PropTypes.bool,
  xsmall: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  semibold: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default function Text({
  muted,
  xsmall,
  small,
  large,
  medium,
  semibold,
  className,
  children,
}) {
  return (
    <Paragraph
      className={clsx(className, {
        'text-gray-500': muted,
        'text-xs': xsmall,
        'text-sm': small,
        'text-lg': large,
        'font-medium': medium,
        'font-semibold': semibold,
      })}
    >
      {children}
    </Paragraph>
  );
}

Text.defaultProps = defaultProps;
Text.propTypes = propTypes;
