import PropTypes from 'prop-types';
import MenuItems from './MenuItems';

const defaultProps = {
  items: [],
};

const menuItemShape = PropTypes.shape({
  title: PropTypes.string,
  url: PropTypes.string,
});

const propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([menuItemShape, PropTypes.arrayOf(menuItemShape)])
  ),
};

export default function Menu({ title, items }) {
  return (
    <div className="flex flex-col">
      <h3 className="mt-0 mb-0 text-base font-semibold">{title}</h3>
      <div className="pt-4">
        <MenuItems items={items} />
      </div>
    </div>
  );
}

Menu.defaultProps = defaultProps;
Menu.propTypes = propTypes;
