import PropTypes from 'prop-types';
import { isObject, isArray } from 'lodash';
import ListItem from '@/components/ListItem';
import UnorderedList from '@/components/UnorderedList';
import uniqueKey from '@/helpers/uniqueKey';
import MenuItem from './MenuItem';

const defaultProps = {
  items: [],
};

const menuItemShape = PropTypes.shape({
  title: PropTypes.string,
  url: PropTypes.string,
});

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([menuItemShape, PropTypes.arrayOf(menuItemShape)])
  ),
};

export default function MenuItems({ items }) {
  return (
    <UnorderedList>
      {items.map((item) => {
        if (isArray(item)) {
          return (
            <ListItem key={uniqueKey('menu')}>
              {item.map(({ title, url, isNew }, index) => {
                const multipleItems = [
                  <MenuItem
                    key={uniqueKey(url)}
                    url={url}
                    title={title}
                    isNew={isNew}
                  />,
                ];

                if (index < item.length - 1) {
                  multipleItems.push(
                    <div key={uniqueKey('menu-multiple-divider')}>
                      &nbsp;y&nbsp;
                    </div>
                  );
                }

                return multipleItems;
              })}
            </ListItem>
          );
        }

        if (isObject(item)) {
          const { title, url, isNew } = item;
          return (
            <ListItem key={uniqueKey(url)}>
              <MenuItem url={url} title={title} isNew={isNew} />
            </ListItem>
          );
        }

        return null;
      })}
    </UnorderedList>
  );
}

MenuItems.defaultProps = defaultProps;
MenuItems.propTypes = propTypes;
